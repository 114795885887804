<template>
  <div class="news-block">
    <div class="container news-container" v-if="newsData">
      <div class="row">
        <div class="col-md-3">

          <router-link to="/news" class="menu-item py-1 px-3">Повернутися до усіх новин</router-link>
        </div>
        <div class="col-md-9">
          {{ newsData.date }}
          <h1 class="main-title">{{ newsData.title }}</h1>
          <div v-html="newsData.text"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import NewsItem from '@/components/NewsItem'

export default {
  components: {
    // NewsItem
  },
  props: ['slug'],
  mounted() {
    if(!this.newsData) this.$router.push('/404');
  },
  computed: {
    newsData() {
      let dataRes = this.$news.filter(t => t.slug == this.slug)
      if(dataRes.length >= 1) return dataRes[0]
      else return false;
    }
  }
}
</script>
